import React from 'react';
import {Button} from 'antd';
import Theme from '../../Theme';
import {useOutlet} from 'reconnect.js';

export default function CheckoutCTA(props) {
  const {
    disabled,
    invalidReason,
    invalidAction,
    onClick,
    label,
    priceOnly = false,
  } = props;
  const [cartPriceInOutlet] = useOutlet('LayaCartPrice');
  return (
    <div
      style={{
        padding: '15px 15px 20px 15px',
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 10,
        }}>
        <div style={{fontSize: 20, color: 'rgb(88, 78, 54)'}}>總金額</div>
        <div style={{flex: 1}} />
        <div style={{fontSize: 32, fontWeight: 'bold'}}>
          {cartPriceInOutlet?.total || 0}
        </div>
      </div>

      {!priceOnly && (
        <>
          <Button
            type="primary"
            size="large"
            style={{width: '100%'}}
            disabled={disabled}
            onClick={onClick}>
            {label}
          </Button>
          {disabled && invalidReason && (
            <div
              style={{marginTop: 8, display: 'flex', justifyContent: 'center'}}>
              <div
                style={{
                  color: Theme.colors.red,
                  textAlign: 'center',
                }}>
                {invalidReason}
              </div>
              {invalidAction ? (
                <Button
                  type="link"
                  onClick={invalidAction.action}
                  style={{
                    marginLeft: 4,
                    padding: 0,
                    height: 'auto',
                    border: 0,
                  }}>
                  {invalidAction.text}
                </Button>
              ) : null}
            </div>
          )}
        </>
      )}
    </div>
  );
}
