function displayPrice(
  price,
  store,
  option = {multipleOf: 1, product: {}, hitAddonCondition: false},
) {
  // if (option.product?.is_add_on_product && option.cart?.items?.length > 0) {
  if (option.hitAddonCondition) {
    price = option.product?.add_on_price ?? price;
  }

  if (!store) {
    return NaN;
  } else {
    return price + (store.price_delta * (option.multipleOf ?? 1) || 0);
  }
}

export {displayPrice};
