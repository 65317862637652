import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import {Pencil} from '@styled-icons/boxicons-solid/Pencil';
import {Trash} from '@styled-icons/boxicons-regular/Trash';
import {PurchaseTagAlt} from '@styled-icons/boxicons-regular/PurchaseTagAlt';
import * as LayaCartUtil from '../../Utils/LayaCartUtil';
import {getItemSummary} from '../../Utils/CartUtil';
import Theme from '../../Theme';
import * as PriceUtil from '../../Utils/PriceUtil';

export default function Cart(props) {
  const {order} = props;
  const [cartInOutlet] = useOutlet('LayaCart');
  const [cartPriceInOutlet] = useOutlet('LayaCartPrice');
  const [variantGroups] = useOutlet('variantGroups');

  const isFromOrder = !!order;
  let cart = isFromOrder ? {...order} : cartInOutlet;
  let cartPrice = isFromOrder ? {...order} : cartPriceInOutlet;
  const discountItems = cart?.discount_items || [];

  if (!cart || !cart.items || !cart.items.length) {
    return (
      <Wrapper style={{padding: 10}}>
        <h3>購物車為空</h3>
      </Wrapper>
    );
  }

  function renderErrorStatus(cartItem) {
    if (isFromOrder) {
      return null;
    }

    const [result, errDetail] = LayaCartUtil.validateCartItem(cartItem);

    if (!result) {
      return (
        <div style={{padding: '8px 0'}}>
          <div style={{color: 'red'}}>{errDetail}</div>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <Wrapper>
      {cart.items.map((cartItem, idx) => {
        const discounts = discountItems.filter(
          (x) => x.detail?.item_index === idx,
        );
        const variantGroup = variantGroups.find(
          (v) => v.name === cartItem.product.group,
        );
        const hideDetailPrice = !!cart?.store?.price_delta;

        return (
          <div className="cart-item" key={idx}>
            <div className="title-bar">
              <div
                style={{
                  flex: 1,
                  fontSize: 18,
                  fontWeight: 'bold',
                  marginBottom: 6,
                }}>
                {cartItem.product.name}{' '}
                {PriceUtil.displayPrice(cartItem.product.price, cart.store, {
                  multipleOf: variantGroup?.price_delta_multiple_of,
                })}
              </div>
              {!isFromOrder && (
                <div
                  style={{
                    alignSelf: 'flex-start',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <div
                    className="edit-button"
                    onClick={() => {
                      AppActions.navigate(
                        `/product/?id=${cartItem.product.id}&cartItemIdx=${idx}`,
                      );
                    }}>
                    <div style={{color: Theme.colors.main, marginRight: 4}}>
                      編輯
                    </div>
                    <Pencil size={16} style={{color: Theme.colors.main}} />
                  </div>
                  <div>
                    <img
                      src="/images/icon-trash.png"
                      onClick={async () => {
                        if (!window.confirm('確認要移除此商品嗎?')) {
                          return;
                        }

                        AppActions.setLoading(true);
                        try {
                          await LayaCartUtil.removeItem(idx);
                        } catch (ex) {
                          console.warn(ex);
                        }
                        AppActions.setLoading(false);
                      }}
                      style={{width: 17, marginLeft: 6}}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="item-summary">
              <div>
                {getItemSummary(cartItem, {hidePrice: hideDetailPrice})}
              </div>

              {cartItem.drink && (
                <div>
                  搭配飲料 / {cartItem.drink.product.name}
                  {!hideDetailPrice &&
                    ' + ' + cartItem.drink.product.price}{' '}
                  {getItemSummary(cartItem.drink, {hidePrice: hideDetailPrice})}
                </div>
              )}

              {cartItem.add_on && (
                <div>
                  加購商品 / {cartItem.add_on.product.name}
                  {!hideDetailPrice && ' + ' + cartItem.add_on.total}{' '}
                  {getItemSummary(cartItem.add_on, {
                    hidePrice: hideDetailPrice,
                  })}
                </div>
              )}

              {discounts.map((d, idx) => (
                <div key={idx} style={{display: 'flex', alignItems: 'center'}}>
                  <PurchaseTagAlt
                    color={Theme.colors.red}
                    size={18}
                    style={{marginRight: 5}}
                  />
                  {d.promotion?.name}
                  {d.detail?.is_drink ? ' 飲料 ' : ''}
                  {!hideDetailPrice && (
                    <div style={{marginLeft: 6}}>-{d.discount_value}</div>
                  )}
                </div>
              ))}

              {!!cartItem.note && (
                <div style={{color: '#888'}}>* {cartItem.note}</div>
              )}
              <div style={{fontWeight: 'bold', marginTop: 6}}>
                共 {cartItem.qty} 份
              </div>
            </div>

            <div className="item-price">
              <div
                style={{
                  flex: 1,
                  fontSize: 20,
                  color:
                    !isFromOrder && cartItem.status !== 0
                      ? '#ccc'
                      : 'rgb(226, 132, 0)',
                  fontWeight: 'bold',
                }}>
                {/* ${cartItem.total} */}
                {cartItem.total -
                  discounts.reduce((sum, x) => {
                    sum += x.discount_value;
                    return sum;
                  }, 0)}
              </div>
            </div>

            {renderErrorStatus(cartItem)}
          </div>
        );
      })}

      <div className="price-bar">
        <div className="row">
          <label>商品總額</label>
          <div style={{color: Theme.colors.red, fontWeight: 'bold'}}>
            {cartPrice?.subtotal}
          </div>
        </div>

        {discountItems.length > 0 ? (
          <div className="row">
            <label>促銷活動</label>
            <div style={{color: Theme.colors.red, fontWeight: 'bold'}}>
              -
              {discountItems.reduce((sum, x) => {
                sum += x.discount_value;
                return sum;
              }, 0)}
            </div>
          </div>
        ) : null}

        {/* {cartPrice && (
          <div className="row">
            <label></label>
            <h2>{cartPrice.total}</h2>
          </div>
        )} */}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .cart-item {
    padding: 15px;
    border-bottom: 1px solid #aaa;

    & > .title-bar {
      display: flex;
      align-items: center;
    }

    & > .item-price {
      display: flex;
      align-items: center;
      margin-top: 6px;
    }
  }

  & > .price-bar {
    padding: 15px;
    width: 100%;
    & > .row {
      display: flex;
      justify-content: space-between;
    }
  }

  & .edit-button {
    border-radius: 50px;
    border: 1px solid ${Theme.colors.main};
    padding: 4px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 6px;
  }
`;
