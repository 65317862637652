import constants from '../../constants';

export function getOrderTypeDisplayStr({orderType, store, total}) {
  const isTableOrder = !!orderType?.table;

  if (isTableOrder) {
    return `桌邊點餐/桌號 ${orderType?.table}`;
  }

  if (orderType?.time === null) {
    let minutes =
      store?.preparation_config?.preparation_time ||
      constants.DEFAULT_STORE_PREPARATION;
    if (total > store?.preparation_config?.doubling_threshold) {
      minutes = minutes * 2;
    }

    return `立即取餐-付款後${minutes}分鐘後可前往取餐`;
  } else if (typeof orderType?.time === 'string') {
    return `預約 ${orderType.time.slice(0, -3)} 取餐`;
  }

  return '---';
}
